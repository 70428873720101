import { useState, useEffect } from "react"
import { useUpdateSj62renovationMutation, useDeleteSj62renovationMutation } from "./sj62renovationsApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../../hooks/useAuth"

const EditSj62renovationForm = ({ sj62renovation, users }) => {

    const { isManager, isAdmin } = useAuth()

    const [updateSj62renovation, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateSj62renovationMutation()

    const [deleteSj62renovation, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteSj62renovationMutation()

    const navigate = useNavigate()

    const [task, setTask] = useState(sj62renovation.task)
    const [priority, setPriority] = useState(sj62renovation.priority)
    const [description, setDescription] = useState(sj62renovation.description)
    const [status, setStatus] = useState(sj62renovation.status)
    const [cost, setCost] = useState(sj62renovation.cost)
    const [budget, setBudget] = useState(sj62renovation.budget)
    const [vendor, setVendor] = useState(sj62renovation.vendor)
    const [material, setMaterial] = useState(sj62renovation.material)
    const [area, setArea] = useState(sj62renovation.area)
    const [productcode, setProductcode] = useState(sj62renovation.productcode)
    const [qty, setQty] = useState(sj62renovation.qty)
    const [unitcost, setUnitcost] = useState(sj62renovation.unitcost)
    const [completed, setCompleted] = useState(sj62renovation.completed)
    const [userId, setUserId] = useState(sj62renovation.user)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setTask('')
            setDescription('')
            setUserId('')
            navigate('/dash/sj62renovations')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onTaskChanged = e => setTask(e.target.value)
    const onPriorityChanged = e => setPriority(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)
    const onStatusChanged = e => setStatus(e.target.value)
    const onCostChanged = e => setCost(e.target.value)
    const onBudgetChanged = e => setBudget(e.target.value)
    const onVendorChanged = e => setVendor(e.target.value)
    const onMaterialChanged = e => setMaterial(e.target.value)
    const onAreaChanged = e => setArea(e.target.value)
    const onProductcodeChanged = e => setProductcode(e.target.value)
    const onQtyChanged = e => setQty(e.target.value)
    const onUnitcostChanged = e => setUnitcost(e.target.value)
    const onCompletedChanged = e => setCompleted(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [task, description, userId].every(Boolean) && !isLoading

    const onSaveSj62renovationClicked = async (e) => {
        if (canSave) {
            await updateSj62renovation({ id: sj62renovation.id, user: userId, task, priority, description, status, cost, budget, vendor, material, area, productcode, qty, unitcost, completed })
        }
    }

    const onDeleteSj62renovationClicked = async () => {
        await deleteSj62renovation({ id: sj62renovation.id })
    }

    const created = new Date(sj62renovation.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(sj62renovation.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}

            > {user.username}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !task ? "form__input--incomplete" : ''
    const validTextClass = !description ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="bg-red-400 p-2 rounded-md text-blue-800 font-bold ml-5"
                title="Delete"
                onClick={onDeleteSj62renovationClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} /> Delete
            </button>
        )
    }

    const content = (
        <>
            <p className="bg-red-400">{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <h2 className="font-bold text-2xl text-gray-600">Edit Sj62renovation #{sj62renovation.ticket}</h2>
                    <div className="">
                        <button
                            className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                            title="Save"
                            onClick={onSaveSj62renovationClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        {deleteButton}
                    </div>
                </div>
                
                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                        task:</label>
                    <input
                        className={`form__input ${validTitleClass}`}
                        id="sj62renovation-title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        value={task}
                        onChange={onTaskChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                        description:</label>
                    <textarea
                        className={`form__input ${validTitleClass}`}
                        id="sj62renovation-title"
                        name="title"
                        type="text"
                        rows={5}
                        autoComplete="off"
                        value={description}
                        onChange={onDescriptionChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                    priority:</label>
                    <input
                        className={`form__input ${validTitleClass}`}
                        id="sj62renovation-title"
                        name="status"
                        type="text"
                        autoComplete="off"
                        value={priority}
                        onChange={onPriorityChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                        status:</label>
                    <input
                        className={`form__input ${validTitleClass}`}
                        id="sj62renovation-title"
                        name="status"
                        type="text"
                        autoComplete="off"
                        value={status}
                        onChange={onStatusChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        cost:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={cost}
                        onChange={onCostChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        budget:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="budget"
                        value={budget}
                        onChange={onBudgetChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        vendor:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={vendor}
                        onChange={onVendorChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        material:</label>
                    <textarea
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        rows={5}
                        value={material}
                        onChange={onMaterialChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        area:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={area}
                        onChange={onAreaChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        productcode:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={productcode}
                        onChange={onProductcodeChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        qty:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={qty}
                        onChange={onQtyChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        unitcost:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={unitcost}
                        onChange={onUnitcostChanged}
                    />
                </div>


                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <div className="form__divider">
                        <label className="form__label form__checkbox-container" htmlFor="sj62renovation-completed">
                            WORK COMPLETE:
                            <input
                                className="form__checkbox"
                                id="sj62renovation-completed"
                                name="completed"
                                type="checkbox"
                                checked={completed}
                                onChange={onCompletedChanged}
                            />
                        </label>

                        <label className="form__label form__checkbox-container" htmlFor="sj62renovation-username">
                            ASSIGNED TO:</label>
                        <select
                            id="sj62renovation-username"
                            name="username"
                            className="form__select"
                            value={userId}
                            onChange={onUserIdChanged}
                        >
                            {options}
                        </select>
                    </div>
                    <div className="form__divider">
                        <p className="form__created">Created:<br />{created}</p>
                        <p className="form__updated">Updated:<br />{updated}</p>
                    </div>
                </div>
            </form>
        </>
    )

    return content
}

export default EditSj62renovationForm